import * as React from "react";
import styled from "styled-components";

import Button from "./styles/Button";

const ContactFormStyles = styled.section`
  .hidden {
    display: none;
  }

  hr {
    border: none;
    border-top: 1px dashed var(--body-text-dark-theme);
    color: #fff;
    background-color: var(--body-text-dark-theme);
    height: 1px;
  }

  input,
  textarea {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    margin: 10px 0;
    color: var(--body-text-light-theme);
    background-color: var(--body-text-dark-theme);

    ::-moz-selection {
      /* Code for Firefox */
      color: var(--body-text-dark-theme);
      background: var(--body-text-light-theme);
    }

    ::selection {
      color: var(--body-text-dark-theme);
      background: var(--body-text-light-theme);
    }
  }
`;

export const ContactForm = () => {
  return (
    <ContactFormStyles>
      <form action="https://api.web3forms.com/submit" method="POST">
        <h2>Contact Us</h2>

        <p>Please share your feedback and suggestions with us.</p>

        {/* Hidden Fields */}
        <input
          type="hidden"
          name="apikey"
          value="4495de00-e2db-4b68-98fa-559ab50bf875"
        />
        <input
          type="hidden"
          name="subject"
          value="You are being contacted from someone on WDN"
        ></input>
        <input type="checkbox" name="botcheck" className="hidden" />
        <input type="hidden" name="recaptcha_response" id="recaptchaResponse" />
        {/* END Hidden Fields */}

        <div>
          <label>
            Name <br />
            <input type="text" name="name" required />
          </label>
        </div>
        <div>
          <label>
            Email <br />
            <input type="email" name="email" required />
          </label>
        </div>
        <div>
          <label>
            Talk to Us! <br />
            <textarea rows="8" name="message" required />
          </label>
        </div>

        <input
          type="hidden"
          name="redirect"
          value="https://web3forms.com/success"
        />
        <Button type="submit">Submit Form</Button>
      </form>
    </ContactFormStyles>
  );
};
