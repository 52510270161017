import styled from "styled-components";
const Button = styled.button`
  background: transparent;
  border: 1px solid #fff;
  padding: 0.5rem 1rem;
  color: var(--body-text-dark-theme);
  margin: 10px 0;
`;

export default Button;
