import * as React from "react";

import { MainTemplate } from "../templates/mainTemplate";
import { ContactForm } from "../components/ContactForm";
import { Seo } from "../components/Seo";

const ContactPage = () => {
  return (
    <>
      <Seo title="WDN | Contact" />
      <MainTemplate>
        <ContactForm />
      </MainTemplate>
    </>
  );
};

export default ContactPage;
